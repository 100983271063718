import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '~/components/seo'
import HeadingInview from '~/components/module/headingInview'

const NotFoundPage = () => (
  <Layout>
    <Seo title="not found" pageUrl="" />
    <div className="notFoundPage">
      <section>
        <h2 className="heading">
          <HeadingInview text="not found" />
        </h2>
        <Link to="/">top</Link>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
